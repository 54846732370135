.top-header {
  overflow: hidden;
}

.bullet {
  border: 1px solid #444;
  border-radius: 12px;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.bullet-primary, .bullet-D {background-color: #007BFF;
  border-radius: 8px; height: 8px; width: 8px;}
.bullet-secondary {background-color: #868E96;}
.bullet-danger {background-color: #DC3545;}
.bullet-success, .bullet-I {background-color: #28A745;}
.bullet-info {background-color: #17A2B8;}
.bullet-warning, .bullet-F {background-color: #FFC107;}
.bullet-black {background-color: #000000;}

.text-size-xs {font-size: 0.8rem;}
.text-size-sm {font-size: 0.9rem;}
.text-size-md {font-size: 1rem;}
.text-size-lg {font-size: 1.2rem;}
.text-size-xl {font-size: 1.4rem;}

.parent .child {
  visibility: hidden;
}
.parent:hover .child {
  visibility: visible;
}

#loading {
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin-left: -100px;
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #000a;
  background-color: #333;
  color: #fff;
}

.notyf {
  z-index: 200000 !important;
}

.mayus {
  text-transform: uppercase;
}

.navbar-brand img {
  max-height: 45px;
}

.td-center {
  vertical-align: middle !important;
  text-align: center !important;
}

.border-limit {
  border: 2px solid #f27474;
  display: inline-block;
  padding: 2px;
  border-radius: 10px;
}

.nowrap {
  white-space: nowrap;
}

footer {
  height: 70px;
  margin-top: 120px;
  background-color: #E1E408;
  color: #73159B;
  font-size: 0.9rem;
  z-index: 1000;
}

footer a.icon-link {
  display: inline-block;
  margin: 5px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #73159B;
  color: #E1E408 !important;
  font-size: 1.2rem;
  text-align: center;
}

.footer-logo {
  max-width: 80px;
}

/* navbar */
/* Custom styles for the vertical navbar */
.vertical-nav {
  width: 80px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 500;
  box-shadow: -2px 0px 8px 0px #827A584D inset;
}
.vertical-nav a {
  display: block;
  padding: 15px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem;
}

.vertical-nav .dropdown-menu {
  text-align: left;
}

.vertical-nav .dropdown-menu a {
  padding: 5px;
}

.vertical-nav .dropdown-item {
  text-align: center;
}

.vertical-nav .fa {
  font-size: 23px;
  display: inline-flex;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0 auto;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
}
/* end navbar */

.content {
  margin-left: 80px;
}

/* Styles for mobile view */
@media (max-width: 767.98px) {
  #app {
    padding-top: 60px;
  }

  .vertical-nav {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
  }
  .vertical-nav .nav-link, .vertical-nav .navbar-brand {
    padding: 10px 10px 10px 5px;
    text-align: center;
    font-size: 0.9rem;
    float: left;
  }

  .vertical-nav .nav-link-logo img {
    max-width: 60px;
  }

  .vertical-nav .nav-link span {
    display: none;
  }

  .vertical-nav .dropdown-menu {
    position: static;
    float: none;
    background-color: #343a40;
    margin-top: 0;
  }
  .vertical-nav .dropdown-item {
    text-align: center;
    padding: 10px;
  }
  .vertical-nav .fa {
    font-size: 1rem;
  }

  .content {
    margin-left: 0;
  }
}
